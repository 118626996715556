'use strict';

var cov_nymy334xq = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/templateDetails/templateDetails.js',
        hash = 'f1f87fdb9aec5c51fde1a5d6138c3ad6cdf528d8',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/templateDetails/templateDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 16
                },
                end: {
                    line: 9,
                    column: 27
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 29
                },
                end: {
                    line: 14,
                    column: 49
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 28
                },
                end: {
                    line: 15,
                    column: 56
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 37
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 19
                }
            },
            '9': {
                start: {
                    line: 19,
                    column: 20
                },
                end: {
                    line: 19,
                    column: 61
                }
            },
            '10': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 46
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 33
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 33
                }
            },
            '14': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 15
                }
            },
            '15': {
                start: {
                    line: 45,
                    column: 16
                },
                end: {
                    line: 45,
                    column: 37
                }
            },
            '16': {
                start: {
                    line: 50,
                    column: 22
                },
                end: {
                    line: 50,
                    column: 27
                }
            },
            '17': {
                start: {
                    line: 51,
                    column: 19
                },
                end: {
                    line: 51,
                    column: 47
                }
            },
            '18': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 9
                }
            },
            '19': {
                start: {
                    line: 53,
                    column: 12
                },
                end: {
                    line: 55,
                    column: 13
                }
            },
            '20': {
                start: {
                    line: 54,
                    column: 16
                },
                end: {
                    line: 54,
                    column: 31
                }
            },
            '21': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 23
                }
            },
            '22': {
                start: {
                    line: 62,
                    column: 0
                },
                end: {
                    line: 70,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 47
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 20
                    },
                    end: {
                        line: 8,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 66
                    },
                    end: {
                        line: 10,
                        column: 13
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 23
                    },
                    end: {
                        line: 11,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 34
                    },
                    end: {
                        line: 12,
                        column: 13
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 13,
                        column: 26
                    },
                    end: {
                        line: 13,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 37
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                line: 13
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 45
                    },
                    end: {
                        line: 18,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 51
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                },
                line: 18
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 26,
                        column: 4
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 14
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                line: 26
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 26
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                line: 31
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 39,
                        column: 4
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 29
                    },
                    end: {
                        line: 47,
                        column: 5
                    }
                },
                line: 39
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 44,
                        column: 18
                    },
                    end: {
                        line: 44,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 24
                    },
                    end: {
                        line: 46,
                        column: 13
                    }
                },
                line: 44
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 19
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                line: 49
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 51,
                        column: 19
                    },
                    end: {
                        line: 51,
                        column: 47
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 51,
                        column: 19
                    },
                    end: {
                        line: 51,
                        column: 32
                    }
                }, {
                    start: {
                        line: 51,
                        column: 36
                    },
                    end: {
                        line: 51,
                        column: 47
                    }
                }],
                line: 51
            },
            '1': {
                loc: {
                    start: {
                        line: 52,
                        column: 8
                    },
                    end: {
                        line: 56,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 52,
                        column: 8
                    },
                    end: {
                        line: 56,
                        column: 9
                    }
                }, {
                    start: {
                        line: 52,
                        column: 8
                    },
                    end: {
                        line: 56,
                        column: 9
                    }
                }],
                line: 52
            },
            '2': {
                loc: {
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 55,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 55,
                        column: 13
                    }
                }, {
                    start: {
                        line: 53,
                        column: 12
                    },
                    end: {
                        line: 55,
                        column: 13
                    }
                }],
                line: 53
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TemplateDetailsController = function () {
    function TemplateDetailsController($mdDialog, $rootScope, $scope) {
        var _this = this;

        _classCallCheck(this, TemplateDetailsController);

        cov_nymy334xq.f[0]++;
        cov_nymy334xq.s[0]++;

        this.$mdDialog = $mdDialog;
        cov_nymy334xq.s[1]++;
        this.$rootScope = $rootScope;
        cov_nymy334xq.s[2]++;
        this.$scope = $scope;
        cov_nymy334xq.s[3]++;
        this.sortableListeners = {
            accept: function accept(sourceItemHandleScope, destSortableScope) {
                cov_nymy334xq.f[1]++;
                cov_nymy334xq.s[4]++;

                return true;
            },
            itemMoved: function itemMoved(event) {
                cov_nymy334xq.f[2]++;
            },
            orderChanged: function orderChanged(event) {
                cov_nymy334xq.f[3]++;

                var newPos = (cov_nymy334xq.s[5]++, event.dest.index + 1);
                var topic = (cov_nymy334xq.s[6]++, event.source.itemScope.topic);
                cov_nymy334xq.s[7]++;
                topic.order = newPos;

                cov_nymy334xq.s[8]++;
                topic.$save(['order']).$then(function () {
                    cov_nymy334xq.f[4]++;
                    cov_nymy334xq.s[9]++;

                    _this.template.$templateTopics.$refresh();
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };
    }

    _createClass(TemplateDetailsController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_nymy334xq.f[5]++;
            cov_nymy334xq.s[10]++;

            this.template.$load('templateTopics');
            cov_nymy334xq.s[11]++;
            this.newDescription = '';
        }
    }, {
        key: 'addTopic',
        value: function addTopic(description) {
            cov_nymy334xq.f[6]++;
            cov_nymy334xq.s[12]++;

            this.template.$templateTopics.$create({
                template: this.template.id,
                name: description
            });
            cov_nymy334xq.s[13]++;
            this.newDescription = '';
        }
    }, {
        key: 'confirmDelete',
        value: function confirmDelete(toDestroy) {
            cov_nymy334xq.f[7]++;
            cov_nymy334xq.s[14]++;

            this.$mdDialog.show(this.$mdDialog.confirm().title("Are you sure you want to delete?").ok('Delete').cancel('Cancel')).then(function () {
                cov_nymy334xq.f[8]++;
                cov_nymy334xq.s[15]++;

                toDestroy.$destroy();
            });
        }
    }, {
        key: 'isEnter',
        value: function isEnter(event) {
            cov_nymy334xq.f[9]++;

            var isEnter = (cov_nymy334xq.s[16]++, false);
            var code = (cov_nymy334xq.s[17]++, (cov_nymy334xq.b[0][0]++, event.keyCode) || (cov_nymy334xq.b[0][1]++, event.which));
            cov_nymy334xq.s[18]++;
            if (code === 13) {
                cov_nymy334xq.b[1][0]++;
                cov_nymy334xq.s[19]++;

                if (!event.shiftKey) {
                    cov_nymy334xq.b[2][0]++;
                    cov_nymy334xq.s[20]++;

                    isEnter = true;
                } else {
                    cov_nymy334xq.b[2][1]++;
                }
            } else {
                cov_nymy334xq.b[1][1]++;
            }
            cov_nymy334xq.s[21]++;
            return isEnter;
        }
    }]);

    return TemplateDetailsController;
}();

cov_nymy334xq.s[22]++;


angular.module('app').component('templateDetails', {
    templateUrl: 'app/components/templateDetails/templateDetails.html',
    controller: TemplateDetailsController,
    bindings: {
        template: '<'
    }
});