'use strict';

var cov_84hv59vtw = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/auth/auth.login.controller.js',
        hash = '4ad5a9943bbc734171e66488e797b460cde99a07',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/auth/auth.login.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 40,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 17
                },
                end: {
                    line: 10,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 25
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 30
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 21,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 15
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 22,
                    column: 29
                }
            },
            '8': {
                start: {
                    line: 24,
                    column: 16
                },
                end: {
                    line: 24,
                    column: 34
                }
            },
            '9': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 33
                }
            },
            '10': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 40
                }
            },
            '11': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 15
                }
            },
            '12': {
                start: {
                    line: 35,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 42
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 40,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Controller',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 72
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 17,
                        column: 17
                    },
                    end: {
                        line: 17,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 29
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 17
            },
            '3': {
                name: 'login',
                decl: {
                    start: {
                        line: 20,
                        column: 17
                    },
                    end: {
                        line: 20,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 26
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 21,
                        column: 58
                    },
                    end: {
                        line: 21,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 76
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                },
                line: 21
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 23,
                        column: 15
                    },
                    end: {
                        line: 23,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 32
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                line: 23
            },
            '6': {
                name: 'logout',
                decl: {
                    start: {
                        line: 28,
                        column: 17
                    },
                    end: {
                        line: 28,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 27
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                },
                line: 28
            },
            '7': {
                name: 'afterLogin',
                decl: {
                    start: {
                        line: 32,
                        column: 17
                    },
                    end: {
                        line: 32,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 31
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 32
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 34,
                        column: 32
                    },
                    end: {
                        line: 34,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 44
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                },
                line: 34
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_84hv59vtw.s[0]++;
(function () {
    'use strict';

    cov_84hv59vtw.f[0]++;
    cov_84hv59vtw.s[1]++;
    angular.module('app').controller('AuthLoginController', Controller);

    /* @ngInject */
    function Controller($state, AuthService, $rootScope, $stateParams) {
        cov_84hv59vtw.f[1]++;

        var vm = (cov_84hv59vtw.s[2]++, this);
        cov_84hv59vtw.s[3]++;
        vm.login = login;

        cov_84hv59vtw.s[4]++;
        vm.authenticated = {};

        cov_84hv59vtw.s[5]++;
        activate();

        function activate() {
            cov_84hv59vtw.f[2]++;
        }

        function login() {
            cov_84hv59vtw.f[3]++;
            cov_84hv59vtw.s[6]++;

            AuthService.login(vm.email, vm.password).then(function (result) {
                cov_84hv59vtw.f[4]++;
                cov_84hv59vtw.s[7]++;

                afterLogin();
            }, function (error) {
                cov_84hv59vtw.f[5]++;
                cov_84hv59vtw.s[8]++;

                vm.member = error;
            });
        }

        function logout() {
            cov_84hv59vtw.f[6]++;
            cov_84hv59vtw.s[9]++;

            AuthService.logout();
        }

        function afterLogin() {
            cov_84hv59vtw.f[7]++;
            cov_84hv59vtw.s[10]++;

            $rootScope.loadStaticData();
            cov_84hv59vtw.s[11]++;
            $rootScope.me.$then(function () {
                cov_84hv59vtw.f[8]++;
                cov_84hv59vtw.s[12]++;

                $state.go('meetingsList');
            });
        }
    }
})();