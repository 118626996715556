'use strict';

var cov_1cxr34lntn = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/meetingDetails/meetingDetails.js',
        hash = '2776797a164c7a126ddec7e19bbc574c252dde12',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/meetingDetails/meetingDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 42
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 50
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 55
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 37
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 29
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 12,
                    column: 27
                }
            },
            '8': {
                start: {
                    line: 17,
                    column: 29
                },
                end: {
                    line: 17,
                    column: 49
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 28
                },
                end: {
                    line: 18,
                    column: 56
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 37
                }
            },
            '11': {
                start: {
                    line: 21,
                    column: 16
                },
                end: {
                    line: 23,
                    column: 19
                }
            },
            '12': {
                start: {
                    line: 22,
                    column: 20
                },
                end: {
                    line: 22,
                    column: 52
                }
            },
            '13': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 49
                }
            },
            '14': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 14
                }
            },
            '15': {
                start: {
                    line: 32,
                    column: 16
                },
                end: {
                    line: 32,
                    column: 49
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 100
                }
            },
            '17': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 11
                }
            },
            '18': {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 15
                }
            },
            '19': {
                start: {
                    line: 41,
                    column: 16
                },
                end: {
                    line: 41,
                    column: 59
                }
            },
            '20': {
                start: {
                    line: 43,
                    column: 16
                },
                end: {
                    line: 43,
                    column: 56
                }
            },
            '21': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 33
                }
            },
            '22': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 54
                }
            },
            '23': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 41
                }
            },
            '24': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 11
                }
            },
            '25': {
                start: {
                    line: 51,
                    column: 12
                },
                end: {
                    line: 57,
                    column: 13
                }
            },
            '26': {
                start: {
                    line: 52,
                    column: 16
                },
                end: {
                    line: 54,
                    column: 19
                }
            },
            '27': {
                start: {
                    line: 53,
                    column: 20
                },
                end: {
                    line: 53,
                    column: 62
                }
            },
            '28': {
                start: {
                    line: 55,
                    column: 16
                },
                end: {
                    line: 55,
                    column: 39
                }
            },
            '29': {
                start: {
                    line: 56,
                    column: 16
                },
                end: {
                    line: 56,
                    column: 54
                }
            },
            '30': {
                start: {
                    line: 62,
                    column: 8
                },
                end: {
                    line: 67,
                    column: 9
                }
            },
            '31': {
                start: {
                    line: 63,
                    column: 20
                },
                end: {
                    line: 63,
                    column: 59
                }
            },
            '32': {
                start: {
                    line: 64,
                    column: 12
                },
                end: {
                    line: 66,
                    column: 13
                }
            },
            '33': {
                start: {
                    line: 65,
                    column: 16
                },
                end: {
                    line: 65,
                    column: 41
                }
            },
            '34': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 18
                }
            },
            '35': {
                start: {
                    line: 72,
                    column: 32
                },
                end: {
                    line: 72,
                    column: 53
                }
            },
            '36': {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 73,
                    column: 58
                }
            },
            '37': {
                start: {
                    line: 74,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 49
                }
            },
            '38': {
                start: {
                    line: 78,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 11
                }
            },
            '39': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 82,
                    column: 38
                }
            },
            '40': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 90,
                    column: 11
                }
            },
            '41': {
                start: {
                    line: 91,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 33
                }
            },
            '42': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 98,
                    column: 11
                }
            },
            '43': {
                start: {
                    line: 99,
                    column: 8
                },
                end: {
                    line: 99,
                    column: 41
                }
            },
            '44': {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 15
                }
            },
            '45': {
                start: {
                    line: 108,
                    column: 16
                },
                end: {
                    line: 108,
                    column: 37
                }
            },
            '46': {
                start: {
                    line: 113,
                    column: 28
                },
                end: {
                    line: 113,
                    column: 33
                }
            },
            '47': {
                start: {
                    line: 114,
                    column: 8
                },
                end: {
                    line: 118,
                    column: 11
                }
            },
            '48': {
                start: {
                    line: 115,
                    column: 12
                },
                end: {
                    line: 117,
                    column: 13
                }
            },
            '49': {
                start: {
                    line: 116,
                    column: 16
                },
                end: {
                    line: 116,
                    column: 37
                }
            },
            '50': {
                start: {
                    line: 119,
                    column: 8
                },
                end: {
                    line: 119,
                    column: 29
                }
            },
            '51': {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 130,
                    column: 11
                }
            },
            '52': {
                start: {
                    line: 124,
                    column: 12
                },
                end: {
                    line: 129,
                    column: 15
                }
            },
            '53': {
                start: {
                    line: 125,
                    column: 16
                },
                end: {
                    line: 128,
                    column: 76
                }
            },
            '54': {
                start: {
                    line: 128,
                    column: 39
                },
                end: {
                    line: 128,
                    column: 74
                }
            },
            '55': {
                start: {
                    line: 134,
                    column: 22
                },
                end: {
                    line: 134,
                    column: 27
                }
            },
            '56': {
                start: {
                    line: 135,
                    column: 19
                },
                end: {
                    line: 135,
                    column: 47
                }
            },
            '57': {
                start: {
                    line: 136,
                    column: 8
                },
                end: {
                    line: 140,
                    column: 9
                }
            },
            '58': {
                start: {
                    line: 137,
                    column: 12
                },
                end: {
                    line: 139,
                    column: 13
                }
            },
            '59': {
                start: {
                    line: 138,
                    column: 16
                },
                end: {
                    line: 138,
                    column: 31
                }
            },
            '60': {
                start: {
                    line: 141,
                    column: 8
                },
                end: {
                    line: 141,
                    column: 23
                }
            },
            '61': {
                start: {
                    line: 146,
                    column: 0
                },
                end: {
                    line: 154,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 108
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 11,
                        column: 20
                    },
                    end: {
                        line: 11,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 66
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                },
                line: 11
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 23
                    },
                    end: {
                        line: 14,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 34
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 16,
                        column: 26
                    },
                    end: {
                        line: 16,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 37
                    },
                    end: {
                        line: 24,
                        column: 13
                    }
                },
                line: 16
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 21,
                        column: 45
                    },
                    end: {
                        line: 21,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 51
                    },
                    end: {
                        line: 23,
                        column: 17
                    }
                },
                line: 21
            },
            '5': {
                name: 'buildToggler',
                decl: {
                    start: {
                        line: 30,
                        column: 17
                    },
                    end: {
                        line: 30,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 43
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 31,
                        column: 19
                    },
                    end: {
                        line: 31,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 30
                    },
                    end: {
                        line: 33,
                        column: 13
                    }
                },
                line: 31
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 37,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 14
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                line: 37
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 39,
                        column: 55
                    },
                    end: {
                        line: 39,
                        column: 56
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 73
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                line: 39
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 40,
                        column: 70
                    },
                    end: {
                        line: 40,
                        column: 71
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 87
                    },
                    end: {
                        line: 42,
                        column: 13
                    }
                },
                line: 40
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 42,
                        column: 21
                    },
                    end: {
                        line: 42,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 44
                    },
                    end: {
                        line: 44,
                        column: 13
                    }
                },
                line: 42
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 50,
                        column: 47
                    },
                    end: {
                        line: 50,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 61
                    },
                    end: {
                        line: 58,
                        column: 9
                    }
                },
                line: 50
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 52,
                        column: 108
                    },
                    end: {
                        line: 52,
                        column: 109
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 122
                    },
                    end: {
                        line: 54,
                        column: 17
                    }
                },
                line: 52
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 61,
                        column: 4
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 20
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 61
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 71,
                        column: 4
                    },
                    end: {
                        line: 71,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 18
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                line: 71
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 77,
                        column: 4
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 19
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                line: 77
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 81,
                        column: 17
                    },
                    end: {
                        line: 81,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 34
                    },
                    end: {
                        line: 83,
                        column: 9
                    }
                },
                line: 81
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 86,
                        column: 4
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 26
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                line: 86
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 94,
                        column: 4
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 94,
                        column: 29
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                line: 94
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 102,
                        column: 4
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 29
                    },
                    end: {
                        line: 110,
                        column: 5
                    }
                },
                line: 102
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 107,
                        column: 18
                    },
                    end: {
                        line: 107,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 107,
                        column: 24
                    },
                    end: {
                        line: 109,
                        column: 13
                    }
                },
                line: 107
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 112,
                        column: 4
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 112,
                        column: 28
                    },
                    end: {
                        line: 120,
                        column: 5
                    }
                },
                line: 112
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 114,
                        column: 43
                    },
                    end: {
                        line: 114,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 60
                    },
                    end: {
                        line: 118,
                        column: 9
                    }
                },
                line: 114
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 122,
                        column: 4
                    },
                    end: {
                        line: 122,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 122,
                        column: 28
                    },
                    end: {
                        line: 131,
                        column: 5
                    }
                },
                line: 122
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 123,
                        column: 90
                    },
                    end: {
                        line: 123,
                        column: 91
                    }
                },
                loc: {
                    start: {
                        line: 123,
                        column: 110
                    },
                    end: {
                        line: 130,
                        column: 9
                    }
                },
                line: 123
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 124,
                        column: 35
                    },
                    end: {
                        line: 124,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 46
                    },
                    end: {
                        line: 129,
                        column: 13
                    }
                },
                line: 124
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 128,
                        column: 25
                    },
                    end: {
                        line: 128,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 128,
                        column: 39
                    },
                    end: {
                        line: 128,
                        column: 74
                    }
                },
                line: 128
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 133,
                        column: 4
                    },
                    end: {
                        line: 133,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 133,
                        column: 19
                    },
                    end: {
                        line: 142,
                        column: 5
                    }
                },
                line: 133
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 51,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 51,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                }, {
                    start: {
                        line: 51,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                }],
                line: 51
            },
            '1': {
                loc: {
                    start: {
                        line: 62,
                        column: 8
                    },
                    end: {
                        line: 67,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 62,
                        column: 8
                    },
                    end: {
                        line: 67,
                        column: 9
                    }
                }, {
                    start: {
                        line: 62,
                        column: 8
                    },
                    end: {
                        line: 67,
                        column: 9
                    }
                }],
                line: 62
            },
            '2': {
                loc: {
                    start: {
                        line: 64,
                        column: 12
                    },
                    end: {
                        line: 66,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 64,
                        column: 12
                    },
                    end: {
                        line: 66,
                        column: 13
                    }
                }, {
                    start: {
                        line: 64,
                        column: 12
                    },
                    end: {
                        line: 66,
                        column: 13
                    }
                }],
                line: 64
            },
            '3': {
                loc: {
                    start: {
                        line: 64,
                        column: 16
                    },
                    end: {
                        line: 64,
                        column: 33
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 64,
                        column: 16
                    },
                    end: {
                        line: 64,
                        column: 17
                    }
                }, {
                    start: {
                        line: 64,
                        column: 21
                    },
                    end: {
                        line: 64,
                        column: 33
                    }
                }],
                line: 64
            },
            '4': {
                loc: {
                    start: {
                        line: 115,
                        column: 12
                    },
                    end: {
                        line: 117,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 115,
                        column: 12
                    },
                    end: {
                        line: 117,
                        column: 13
                    }
                }, {
                    start: {
                        line: 115,
                        column: 12
                    },
                    end: {
                        line: 117,
                        column: 13
                    }
                }],
                line: 115
            },
            '5': {
                loc: {
                    start: {
                        line: 135,
                        column: 19
                    },
                    end: {
                        line: 135,
                        column: 47
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 135,
                        column: 19
                    },
                    end: {
                        line: 135,
                        column: 32
                    }
                }, {
                    start: {
                        line: 135,
                        column: 36
                    },
                    end: {
                        line: 135,
                        column: 47
                    }
                }],
                line: 135
            },
            '6': {
                loc: {
                    start: {
                        line: 136,
                        column: 8
                    },
                    end: {
                        line: 140,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 136,
                        column: 8
                    },
                    end: {
                        line: 140,
                        column: 9
                    }
                }, {
                    start: {
                        line: 136,
                        column: 8
                    },
                    end: {
                        line: 140,
                        column: 9
                    }
                }],
                line: 136
            },
            '7': {
                loc: {
                    start: {
                        line: 137,
                        column: 12
                    },
                    end: {
                        line: 139,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 137,
                        column: 12
                    },
                    end: {
                        line: 139,
                        column: 13
                    }
                }, {
                    start: {
                        line: 137,
                        column: 12
                    },
                    end: {
                        line: 139,
                        column: 13
                    }
                }],
                line: 137
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MeetingDetailsController = function () {
    function MeetingDetailsController(UsersTable, TemplatesTable, TemplateTopicsTable, $mdDialog, $mdSidenav, $rootScope, $scope) {
        var _this = this;

        _classCallCheck(this, MeetingDetailsController);

        cov_1cxr34lntn.f[0]++;
        cov_1cxr34lntn.s[0]++;

        this.users = UsersTable.$search();
        cov_1cxr34lntn.s[1]++;
        this.$mdDialog = $mdDialog;
        cov_1cxr34lntn.s[2]++;
        this.templates = TemplatesTable.$search();
        cov_1cxr34lntn.s[3]++;
        this.templateTopicsTable = TemplateTopicsTable;
        cov_1cxr34lntn.s[4]++;
        this.$rootScope = $rootScope;
        cov_1cxr34lntn.s[5]++;
        this.$scope = $scope;
        cov_1cxr34lntn.s[6]++;
        this.sortableListeners = {
            accept: function accept(sourceItemHandleScope, destSortableScope) {
                cov_1cxr34lntn.f[1]++;
                cov_1cxr34lntn.s[7]++;

                return true;
            },
            itemMoved: function itemMoved(event) {
                cov_1cxr34lntn.f[2]++;
            },
            orderChanged: function orderChanged(event) {
                cov_1cxr34lntn.f[3]++;

                var newPos = (cov_1cxr34lntn.s[8]++, event.dest.index + 1);
                var topic = (cov_1cxr34lntn.s[9]++, event.source.itemScope.topic);
                cov_1cxr34lntn.s[10]++;
                topic.order = newPos;

                cov_1cxr34lntn.s[11]++;
                topic.$save(['order']).$then(function () {
                    cov_1cxr34lntn.f[4]++;
                    cov_1cxr34lntn.s[12]++;

                    _this.meeting.$topics.$refresh();
                });
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };

        cov_1cxr34lntn.s[13]++;
        this.toggleRight = buildToggler('right');

        function buildToggler(componentId) {
            cov_1cxr34lntn.f[5]++;
            cov_1cxr34lntn.s[14]++;

            return function () {
                cov_1cxr34lntn.f[6]++;
                cov_1cxr34lntn.s[15]++;

                $mdSidenav(componentId).toggle();
            };
        }
    }

    _createClass(MeetingDetailsController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            cov_1cxr34lntn.f[7]++;
            cov_1cxr34lntn.s[16]++;

            this.meeting.$load('meetingUsers').$load('topics').$load('decisions').$load('meetingFiles');
            cov_1cxr34lntn.s[17]++;
            this.meeting.$meetingUsers.$load('user').$then(function (meetingUsers) {
                cov_1cxr34lntn.f[8]++;
                cov_1cxr34lntn.s[18]++;

                meetingUsers.$search({ user: _this2.$rootScope.me.id }).$then(function (meetingUser) {
                    cov_1cxr34lntn.f[9]++;
                    cov_1cxr34lntn.s[19]++;

                    meetingUser.$load('meeting').$load('user');
                }).$then(function (searchMeetingUser) {
                    cov_1cxr34lntn.f[10]++;
                    cov_1cxr34lntn.s[20]++;

                    _this2.meetingUser = searchMeetingUser[0];
                });
            });

            cov_1cxr34lntn.s[21]++;
            this.newDescription = '';
            cov_1cxr34lntn.s[22]++;
            this.duration = this.meeting.scheduleDuration;
            cov_1cxr34lntn.s[23]++;
            this.newDecisionDescription = '';
            cov_1cxr34lntn.s[24]++;
            this.$scope.$watch('$ctrl.fileUpload', function (uploaded) {
                cov_1cxr34lntn.f[11]++;
                cov_1cxr34lntn.s[25]++;

                if (angular.isString(uploaded)) {
                    cov_1cxr34lntn.b[0][0]++;
                    cov_1cxr34lntn.s[26]++;

                    _this2.meeting.$meetingFiles.$create({ meeting: _this2.meeting.id, file: _this2.fileUpload }).$then(function (newImage) {
                        cov_1cxr34lntn.f[12]++;
                        cov_1cxr34lntn.s[27]++;

                        _this2.meeting.$meetingFiles.$add(newImage);
                    });
                    cov_1cxr34lntn.s[28]++;
                    _this2.fileUpload = null;
                    cov_1cxr34lntn.s[29]++;
                    _this2.meeting.$refresh('meetingFiles');
                } else {
                    cov_1cxr34lntn.b[0][1]++;
                }
            });
        }
    }, {
        key: 'fileToName',
        value: function fileToName(url) {
            cov_1cxr34lntn.f[13]++;
            cov_1cxr34lntn.s[30]++;

            if (url) {
                cov_1cxr34lntn.b[1][0]++;

                var m = (cov_1cxr34lntn.s[31]++, url.toString().match(/.*\/(.+?)\.(.+)/));
                cov_1cxr34lntn.s[32]++;
                if ((cov_1cxr34lntn.b[3][0]++, m) && (cov_1cxr34lntn.b[3][1]++, m.length > 1)) {
                    cov_1cxr34lntn.b[2][0]++;
                    cov_1cxr34lntn.s[33]++;

                    return m[1] + '.' + m[2];
                } else {
                    cov_1cxr34lntn.b[2][1]++;
                }
            } else {
                cov_1cxr34lntn.b[1][1]++;
            }
            cov_1cxr34lntn.s[34]++;
            return "";
        }
    }, {
        key: 'editDuration',
        value: function editDuration() {
            cov_1cxr34lntn.f[14]++;

            var scheduledDuration = (cov_1cxr34lntn.s[35]++, this.duration + ':00');
            cov_1cxr34lntn.s[36]++;
            this.meeting.scheduleDuration = scheduledDuration;
            cov_1cxr34lntn.s[37]++;
            this.meeting.$save(['scheduleDuration']);
        }
    }, {
        key: 'addUser',
        value: function addUser(index) {
            cov_1cxr34lntn.f[15]++;
            cov_1cxr34lntn.s[38]++;

            this.meeting.$meetingUsers.$create({
                meeting: this.meeting.id,
                user: index
            }).$then(function (meetingUser) {
                cov_1cxr34lntn.f[16]++;
                cov_1cxr34lntn.s[39]++;

                meetingUser.$load('user');
            });
        }
    }, {
        key: 'addTopic',
        value: function addTopic(description) {
            cov_1cxr34lntn.f[17]++;
            cov_1cxr34lntn.s[40]++;

            this.meeting.$topics.$create({
                meeting: this.meeting.id,
                description: description
            });
            cov_1cxr34lntn.s[41]++;
            this.newDescription = '';
        }
    }, {
        key: 'addDecision',
        value: function addDecision(description) {
            cov_1cxr34lntn.f[18]++;
            cov_1cxr34lntn.s[42]++;

            this.meeting.$decisions.$create({
                meeting: this.meeting.id,
                description: description
            });
            cov_1cxr34lntn.s[43]++;
            this.newDecisionDescription = '';
        }
    }, {
        key: 'confirmDelete',
        value: function confirmDelete(toDestroy) {
            cov_1cxr34lntn.f[19]++;
            cov_1cxr34lntn.s[44]++;

            this.$mdDialog.show(this.$mdDialog.confirm().title("Are you sure you want to delete?").ok('Delete').cancel('Cancel')).then(function () {
                cov_1cxr34lntn.f[20]++;
                cov_1cxr34lntn.s[45]++;

                toDestroy.$destroy();
            });
        }
    }, {
        key: 'userIsParticipant',
        value: function userIsParticipant(user) {
            cov_1cxr34lntn.f[21]++;

            var isParticipant = (cov_1cxr34lntn.s[46]++, false);
            cov_1cxr34lntn.s[47]++;
            this.meeting.$meetingUsers.forEach(function (meetingUser) {
                cov_1cxr34lntn.f[22]++;
                cov_1cxr34lntn.s[48]++;

                if (meetingUser.user == user.id) {
                    cov_1cxr34lntn.b[4][0]++;
                    cov_1cxr34lntn.s[49]++;

                    isParticipant = true;
                } else {
                    cov_1cxr34lntn.b[4][1]++;
                }
            });
            cov_1cxr34lntn.s[50]++;
            return isParticipant;
        }
    }, {
        key: 'addTemplate',
        value: function addTemplate(templateId) {
            var _this3 = this;

            cov_1cxr34lntn.f[23]++;
            cov_1cxr34lntn.s[51]++;

            this.templateTopicsTable.$search({ template: templateId, ordering: 'order' }).$then(function (templateTopics) {
                cov_1cxr34lntn.f[24]++;
                cov_1cxr34lntn.s[52]++;

                templateTopics.forEach(function (topic) {
                    cov_1cxr34lntn.f[25]++;
                    cov_1cxr34lntn.s[53]++;

                    _this3.meeting.$topics.$create({
                        meeting: _this3.meeting.id,
                        description: topic.name
                    }).$then(function (newTopic) {
                        cov_1cxr34lntn.f[26]++;
                        cov_1cxr34lntn.s[54]++;
                        return _this3.meeting.$topics.$add(newTopic);
                    });
                });
            });
        }
    }, {
        key: 'isEnter',
        value: function isEnter(event) {
            cov_1cxr34lntn.f[27]++;

            var isEnter = (cov_1cxr34lntn.s[55]++, false);
            var code = (cov_1cxr34lntn.s[56]++, (cov_1cxr34lntn.b[5][0]++, event.keyCode) || (cov_1cxr34lntn.b[5][1]++, event.which));
            cov_1cxr34lntn.s[57]++;
            if (code === 13) {
                cov_1cxr34lntn.b[6][0]++;
                cov_1cxr34lntn.s[58]++;

                if (!event.shiftKey) {
                    cov_1cxr34lntn.b[7][0]++;
                    cov_1cxr34lntn.s[59]++;

                    isEnter = true;
                } else {
                    cov_1cxr34lntn.b[7][1]++;
                }
            } else {
                cov_1cxr34lntn.b[6][1]++;
            }
            cov_1cxr34lntn.s[60]++;
            return isEnter;
        }
    }]);

    return MeetingDetailsController;
}();

cov_1cxr34lntn.s[61]++;


angular.module('app').component('meetingDetails', {
    templateUrl: 'app/components/meetingDetails/meetingDetails.html',
    controller: MeetingDetailsController,
    bindings: {
        meeting: '<'
    }
});