'use strict';

var cov_2luh6lp6xx = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/editProfile/editProfile.js',
        hash = '9b682220685df561af928c28a34f162c60b5d527',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/editProfile/editProfile.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 13
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 16
                },
                end: {
                    line: 13,
                    column: 51
                }
            },
            '7': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 19
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 20
                },
                end: {
                    line: 15,
                    column: 99
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 20
                },
                end: {
                    line: 16,
                    column: 59
                }
            },
            '10': {
                start: {
                    line: 18,
                    column: 20
                },
                end: {
                    line: 18,
                    column: 98
                }
            },
            '11': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 39
                }
            },
            '12': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 16
                },
                end: {
                    line: 29,
                    column: 91
                }
            },
            '15': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 11
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 88
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 67
                }
            },
            '19': {
                start: {
                    line: 44,
                    column: 0
                },
                end: {
                    line: 52,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 68
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 42
                    },
                    end: {
                        line: 7,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 48
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 50
                    },
                    end: {
                        line: 14,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 56
                    },
                    end: {
                        line: 17,
                        column: 17
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 17,
                        column: 19
                    },
                    end: {
                        line: 17,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 25
                    },
                    end: {
                        line: 19,
                        column: 17
                    }
                },
                line: 17
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 4
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 18
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 27,
                        column: 40
                    },
                    end: {
                        line: 27,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 46
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                line: 27
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 28,
                        column: 62
                    },
                    end: {
                        line: 28,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 68
                    },
                    end: {
                        line: 30,
                        column: 13
                    }
                },
                line: 28
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 30,
                        column: 15
                    },
                    end: {
                        line: 30,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 21
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                line: 30
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 18
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 36
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 37,
                        column: 64
                    },
                    end: {
                        line: 37,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 70
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                line: 37
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                }, {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                }],
                line: 12
            },
            '1': {
                loc: {
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 12,
                        column: 92
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 12,
                        column: 49
                    }
                }, {
                    start: {
                        line: 12,
                        column: 53
                    },
                    end: {
                        line: 12,
                        column: 92
                    }
                }],
                line: 12
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EditProfileController = function () {
    function EditProfileController($mdToast, AuthService, $state, $rootScope, $scope) {
        var _this = this;

        _classCallCheck(this, EditProfileController);

        cov_2luh6lp6xx.f[0]++;
        cov_2luh6lp6xx.s[0]++;

        this.$mdToast = $mdToast;
        cov_2luh6lp6xx.s[1]++;
        this.AuthService = AuthService;
        cov_2luh6lp6xx.s[2]++;
        this.$state = $state;

        cov_2luh6lp6xx.s[3]++;
        $scope.$watch('$ctrl.fileUpload', function () {
            cov_2luh6lp6xx.f[1]++;
            cov_2luh6lp6xx.s[4]++;

            console.log({
                'this': _this.fileUpload,
                'root': $rootScope.me.avatar
            });
            cov_2luh6lp6xx.s[5]++;
            if ((cov_2luh6lp6xx.b[1][0]++, angular.isString(_this.fileUpload)) && (cov_2luh6lp6xx.b[1][1]++, _this.fileUpload != $rootScope.me.avatar)) {
                cov_2luh6lp6xx.b[0][0]++;
                cov_2luh6lp6xx.s[6]++;

                _this.user.avatar = _this.fileUpload;
                cov_2luh6lp6xx.s[7]++;
                _this.user.$save(['avatar']).$then(function () {
                    cov_2luh6lp6xx.f[2]++;
                    cov_2luh6lp6xx.s[8]++;

                    $mdToast.show($mdToast.simple().textContent(gettext('Profile image updated')));
                    cov_2luh6lp6xx.s[9]++;
                    AuthService.refreshAuthenticatedInfo();
                }, function () {
                    cov_2luh6lp6xx.f[3]++;
                    cov_2luh6lp6xx.s[10]++;

                    $mdToast.show($mdToast.simple().textContent(gettext('An error has ocurred')));
                });
                cov_2luh6lp6xx.s[11]++;
                _this.fileUpload = null;
            } else {
                cov_2luh6lp6xx.b[0][1]++;
            }
        });
    }

    _createClass(EditProfileController, [{
        key: 'changeInfo',
        value: function changeInfo() {
            var _this2 = this;

            cov_2luh6lp6xx.f[4]++;
            cov_2luh6lp6xx.s[12]++;

            this.user.$save(['name']).$then(function () {
                cov_2luh6lp6xx.f[5]++;
                cov_2luh6lp6xx.s[13]++;

                _this2.AuthService.refreshAuthenticatedInfo().$then(function () {
                    cov_2luh6lp6xx.f[6]++;
                    cov_2luh6lp6xx.s[14]++;

                    _this2.$mdToast.show(_this2.$mdToast.simple().textContent('Profile updated!'));
                }, function () {
                    cov_2luh6lp6xx.f[7]++;
                    cov_2luh6lp6xx.s[15]++;

                    _this2.$state.go('logout');
                });
            });
        }
    }, {
        key: 'changePass',
        value: function changePass() {
            var _this3 = this;

            cov_2luh6lp6xx.f[8]++;
            cov_2luh6lp6xx.s[16]++;

            this.user.$save(['password', 'confirm_password']).$then(function () {
                cov_2luh6lp6xx.f[9]++;
                cov_2luh6lp6xx.s[17]++;

                _this3.$mdToast.show(_this3.$mdToast.simple().textContent('Password changed!'));
                cov_2luh6lp6xx.s[18]++;
                _this3.user.password = _this3.user.confirm_password = null;
            });
        }
    }]);

    return EditProfileController;
}();

cov_2luh6lp6xx.s[19]++;


angular.module('app').component('editProfile', {
    templateUrl: 'app/components/editProfile/editProfile.html',
    controller: EditProfileController,
    bindings: {
        user: '<'
    }
});