'use strict';

var cov_2mi9unfvmc = function () {
    var path = '/home/lobo/repos/meetings-app/src/routes.js',
        hash = 'f4b90a14bf860a8babe5bfa069a1c7bac2a53441',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 22
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 98,
                    column: 7
                }
            },
            '4': {
                start: {
                    line: 51,
                    column: 34
                },
                end: {
                    line: 51,
                    column: 47
                }
            },
            '5': {
                start: {
                    line: 64,
                    column: 41
                },
                end: {
                    line: 64,
                    column: 103
                }
            },
            '6': {
                start: {
                    line: 64,
                    column: 85
                },
                end: {
                    line: 64,
                    column: 93
                }
            },
            '7': {
                start: {
                    line: 71,
                    column: 54
                },
                end: {
                    line: 71,
                    column: 127
                }
            },
            '8': {
                start: {
                    line: 71,
                    column: 110
                },
                end: {
                    line: 71,
                    column: 117
                }
            },
            '9': {
                start: {
                    line: 78,
                    column: 41
                },
                end: {
                    line: 78,
                    column: 103
                }
            },
            '10': {
                start: {
                    line: 78,
                    column: 85
                },
                end: {
                    line: 78,
                    column: 93
                }
            },
            '11': {
                start: {
                    line: 85,
                    column: 43
                },
                end: {
                    line: 85,
                    column: 108
                }
            },
            '12': {
                start: {
                    line: 85,
                    column: 89
                },
                end: {
                    line: 85,
                    column: 98
                }
            },
            '13': {
                start: {
                    line: 92,
                    column: 56
                },
                end: {
                    line: 92,
                    column: 132
                }
            },
            '14': {
                start: {
                    line: 92,
                    column: 114
                },
                end: {
                    line: 92,
                    column: 122
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 78
                    },
                    end: {
                        line: 99,
                        column: 1
                    }
                },
                line: 6
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 51,
                        column: 18
                    },
                    end: {
                        line: 51,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 34
                    },
                    end: {
                        line: 51,
                        column: 47
                    }
                },
                line: 51
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 64,
                        column: 22
                    },
                    end: {
                        line: 64,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 41
                    },
                    end: {
                        line: 64,
                        column: 103
                    }
                },
                line: 64
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 64,
                        column: 71
                    },
                    end: {
                        line: 64,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 85
                    },
                    end: {
                        line: 64,
                        column: 93
                    }
                },
                line: 64
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 71,
                        column: 21
                    },
                    end: {
                        line: 71,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 54
                    },
                    end: {
                        line: 71,
                        column: 127
                    }
                },
                line: 71
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 71,
                        column: 97
                    },
                    end: {
                        line: 71,
                        column: 98
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 110
                    },
                    end: {
                        line: 71,
                        column: 117
                    }
                },
                line: 71
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 78,
                        column: 22
                    },
                    end: {
                        line: 78,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 78,
                        column: 41
                    },
                    end: {
                        line: 78,
                        column: 103
                    }
                },
                line: 78
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 78,
                        column: 71
                    },
                    end: {
                        line: 78,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 78,
                        column: 85
                    },
                    end: {
                        line: 78,
                        column: 93
                    }
                },
                line: 78
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 85,
                        column: 23
                    },
                    end: {
                        line: 85,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 85,
                        column: 43
                    },
                    end: {
                        line: 85,
                        column: 108
                    }
                },
                line: 85
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 85,
                        column: 74
                    },
                    end: {
                        line: 85,
                        column: 75
                    }
                },
                loc: {
                    start: {
                        line: 85,
                        column: 89
                    },
                    end: {
                        line: 85,
                        column: 98
                    }
                },
                line: 85
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 92,
                        column: 22
                    },
                    end: {
                        line: 92,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 56
                    },
                    end: {
                        line: 92,
                        column: 132
                    }
                },
                line: 92
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 92,
                        column: 100
                    },
                    end: {
                        line: 92,
                        column: 101
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 114
                    },
                    end: {
                        line: 92,
                        column: 122
                    }
                },
                line: 92
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2mi9unfvmc.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_2mi9unfvmc.f[0]++;
    cov_2mi9unfvmc.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_2mi9unfvmc.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_2mi9unfvmc.s[3]++;
    $stateProvider.state('login', {
        url: '/login',
        templateUrl: 'app/components/auth/views/auth.login.view.html',
        controller: 'AuthLoginController',
        controllerAs: 'vm'
    }).state('logout', {
        url: '/logout',
        template: '<div></div>',
        controller: 'AuthLogoutController',
        controllerAs: 'vm'
    }).state('forgot', {
        url: '/forgot_password',
        templateUrl: 'app/components/auth/views/auth.forgot.html',
        controller: 'AuthForgotController',
        controllerAs: 'vm'
    }).state('set_password', {
        url: '/reset_password?token=&email=',
        templateUrl: 'app/components/auth/views/auth.set.password.html',
        controller: 'AuthSetPasswordController',
        controllerAs: 'vm'
    }).state('register', {
        url: '/register',
        templateUrl: 'app/components/auth/views/auth.register.view.html',
        controller: 'AuthRegisterController',
        controllerAs: 'vm'
    }).state('activate', {
        url: '/activate?token=',
        template: '<div></div>',
        controller: 'AuthActivateController',
        controllerAs: 'vm'
    }).state('profile', {
        url: '/profile',
        template: '<edit-profile user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user($rootScope) {
                cov_2mi9unfvmc.f[1]++;
                cov_2mi9unfvmc.s[4]++;
                return $rootScope.me;
            }
        }
    }).state('dashboard', {
        url: '/',
        controller: 'DashboardController',
        controllerAs: 'vm',
        template: '<div></div>'
    }).state('meetingsList', {
        url: '/meetings?page&project',
        template: '<meetings-list meetings="$resolve.meetings" flex layout="column"/>',
        resolve: {
            meetings: function meetings(MeetingsTable) {
                cov_2mi9unfvmc.f[2]++;
                cov_2mi9unfvmc.s[5]++;
                return MeetingsTable.$search().$then(function (meetings) {
                    cov_2mi9unfvmc.f[3]++;
                    cov_2mi9unfvmc.s[6]++;
                    return meetings;
                }).$promise;
            }
        }
    }).state('meetingDetails', {
        url: '/meeting/:id',
        template: '<meeting-details meeting="$resolve.meeting" flex layout="column">',
        resolve: {
            meeting: function meeting(MeetingsTable, $stateParams) {
                cov_2mi9unfvmc.f[4]++;
                cov_2mi9unfvmc.s[7]++;
                return MeetingsTable.$find($stateParams.id).$then(function (meeting) {
                    cov_2mi9unfvmc.f[5]++;
                    cov_2mi9unfvmc.s[8]++;
                    return meeting;
                }).$promise;
            }
        }
    }).state('projectsList', {
        url: '/projects',
        template: '<projects-list projects="$resolve.projects" flex layout="column"/>',
        resolve: {
            projects: function projects(ProjectsTable) {
                cov_2mi9unfvmc.f[6]++;
                cov_2mi9unfvmc.s[9]++;
                return ProjectsTable.$search().$then(function (projects) {
                    cov_2mi9unfvmc.f[7]++;
                    cov_2mi9unfvmc.s[10]++;
                    return projects;
                }).$promise;
            }
        }
    }).state('templatesList', {
        url: '/templates',
        template: '<templates-list templates="$resolve.templates" flex layout="column"/>',
        resolve: {
            templates: function templates(TemplatesTable) {
                cov_2mi9unfvmc.f[8]++;
                cov_2mi9unfvmc.s[11]++;
                return TemplatesTable.$search().$then(function (templates) {
                    cov_2mi9unfvmc.f[9]++;
                    cov_2mi9unfvmc.s[12]++;
                    return templates;
                }).$promise;
            }
        }
    }).state('templateDetails', {
        url: '/template/:id',
        template: '<template-details template="$resolve.template" flex layout="column">',
        resolve: {
            template: function template(TemplatesTable, $stateParams) {
                cov_2mi9unfvmc.f[10]++;
                cov_2mi9unfvmc.s[13]++;
                return TemplatesTable.$find($stateParams.id).$then(function (template) {
                    cov_2mi9unfvmc.f[11]++;
                    cov_2mi9unfvmc.s[14]++;
                    return template;
                }).$promise;
            }
        }
    }).state('newMeeting', {
        url: '/meetings/new',
        template: '<new-meeting flex layout="column">'
    });
}