'use strict';

var cov_2iu1ul0tc0 = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/shared/html/maintoolbar/maintoolbar.js',
        hash = '54a7adfc1c92308c4457035c1e746174467d5879',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/shared/html/maintoolbar/maintoolbar.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 34
                }
            },
            '1': {
                start: {
                    line: 9,
                    column: 0
                },
                end: {
                    line: 14,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MainToolbarController = function MainToolbarController($rootScope) {
    _classCallCheck(this, MainToolbarController);

    cov_2iu1ul0tc0.f[0]++;
    cov_2iu1ul0tc0.s[0]++;

    this.user = $rootScope.me;
};

cov_2iu1ul0tc0.s[1]++;


angular.module('app').component('maintoolbar', {
    templateUrl: 'app/shared/html/maintoolbar/maintoolbar.html',
    controller: MainToolbarController
});