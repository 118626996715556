'use strict';

var cov_2iniejme99 = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/shared/js/projects.js',
        hash = 'b641ceb9a4984c4e9e3b51abc70ca9c9e3e9a7d1',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/shared/js/projects.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 38
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 13
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 19
                },
                end: {
                    line: 12,
                    column: 77
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 57
                },
                end: {
                    line: 12,
                    column: 76
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 2
                },
                end: {
                    line: 13,
                    column: 40
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 17,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'projects',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 34
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 32
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 12,
                        column: 46
                    },
                    end: {
                        line: 12,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 57
                    },
                    end: {
                        line: 12,
                        column: 76
                    }
                },
                line: 12
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }, {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }],
                line: 11
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2iniejme99.s[0]++;
(function () {
    'use strict';

    cov_2iniejme99.f[0]++;
    cov_2iniejme99.s[1]++;
    angular.module('app').filter('projects', projects);

    /* @ngInject */
    function projects($rootScope) {
        cov_2iniejme99.f[1]++;
        cov_2iniejme99.s[2]++;

        return function (input) {
            cov_2iniejme99.f[2]++;
            cov_2iniejme99.s[3]++;

            if (input) {
                cov_2iniejme99.b[0][0]++;

                var filtered = (cov_2iniejme99.s[4]++, $rootScope.projects.filter(function (project) {
                    cov_2iniejme99.f[3]++;
                    cov_2iniejme99.s[5]++;
                    return project.id == input;
                }));
                cov_2iniejme99.s[6]++;
                return filtered[0].name.toUpperCase();
            } else {
                cov_2iniejme99.b[0][1]++;
            }
        };
    }
})();