'use strict';

var cov_24qpqabz0h = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/newMeeting/newMeeting.js',
        hash = '685522ed388cf2f0d85243806dcfd152214f1106',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/newMeeting/newMeeting.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 42
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 50
                }
            },
            '2': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 55
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 51
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 39
                }
            },
            '6': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 51
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 35
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 37
                }
            },
            '9': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 29
                }
            },
            '10': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 29
                }
            },
            '11': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '12': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 27
                }
            },
            '13': {
                start: {
                    line: 24,
                    column: 29
                },
                end: {
                    line: 24,
                    column: 45
                }
            },
            '14': {
                start: {
                    line: 25,
                    column: 28
                },
                end: {
                    line: 25,
                    column: 56
                }
            },
            '15': {
                start: {
                    line: 26,
                    column: 28
                },
                end: {
                    line: 26,
                    column: 61
                }
            },
            '16': {
                start: {
                    line: 27,
                    column: 31
                },
                end: {
                    line: 27,
                    column: 56
                }
            },
            '17': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 52
                }
            },
            '18': {
                start: {
                    line: 29,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 17
                }
            },
            '19': {
                start: {
                    line: 30,
                    column: 20
                },
                end: {
                    line: 30,
                    column: 54
                }
            },
            '20': {
                start: {
                    line: 33,
                    column: 20
                },
                end: {
                    line: 33,
                    column: 67
                }
            },
            '21': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 49
                }
            },
            '22': {
                start: {
                    line: 42,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 14
                }
            },
            '23': {
                start: {
                    line: 43,
                    column: 16
                },
                end: {
                    line: 43,
                    column: 49
                }
            },
            '24': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 31
                }
            },
            '25': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 121
                }
            },
            '26': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 136
                }
            },
            '27': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 28
                }
            },
            '28': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 31
                }
            },
            '29': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 31
                }
            },
            '30': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 32
                }
            },
            '31': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 33
                }
            },
            '32': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 32
                }
            },
            '33': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 62
                }
            },
            '34': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 11
                }
            },
            '35': {
                start: {
                    line: 61,
                    column: 12
                },
                end: {
                    line: 64,
                    column: 13
                }
            },
            '36': {
                start: {
                    line: 62,
                    column: 16
                },
                end: {
                    line: 62,
                    column: 56
                }
            },
            '37': {
                start: {
                    line: 63,
                    column: 16
                },
                end: {
                    line: 63,
                    column: 39
                }
            },
            '38': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 9
                }
            },
            '39': {
                start: {
                    line: 70,
                    column: 20
                },
                end: {
                    line: 70,
                    column: 59
                }
            },
            '40': {
                start: {
                    line: 71,
                    column: 12
                },
                end: {
                    line: 73,
                    column: 13
                }
            },
            '41': {
                start: {
                    line: 72,
                    column: 16
                },
                end: {
                    line: 72,
                    column: 41
                }
            },
            '42': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 18
                }
            },
            '43': {
                start: {
                    line: 79,
                    column: 32
                },
                end: {
                    line: 79,
                    column: 53
                }
            },
            '44': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 80,
                    column: 58
                }
            },
            '45': {
                start: {
                    line: 84,
                    column: 8
                },
                end: {
                    line: 84,
                    column: 37
                }
            },
            '46': {
                start: {
                    line: 88,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 15
                }
            },
            '47': {
                start: {
                    line: 93,
                    column: 16
                },
                end: {
                    line: 93,
                    column: 77
                }
            },
            '48': {
                start: {
                    line: 98,
                    column: 28
                },
                end: {
                    line: 98,
                    column: 33
                }
            },
            '49': {
                start: {
                    line: 99,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 9
                }
            },
            '50': {
                start: {
                    line: 100,
                    column: 12
                },
                end: {
                    line: 100,
                    column: 33
                }
            },
            '51': {
                start: {
                    line: 102,
                    column: 8
                },
                end: {
                    line: 102,
                    column: 29
                }
            },
            '52': {
                start: {
                    line: 106,
                    column: 8
                },
                end: {
                    line: 106,
                    column: 69
                }
            },
            '53': {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 112,
                    column: 10
                }
            },
            '54': {
                start: {
                    line: 113,
                    column: 8
                },
                end: {
                    line: 113,
                    column: 33
                }
            },
            '55': {
                start: {
                    line: 117,
                    column: 8
                },
                end: {
                    line: 123,
                    column: 15
                }
            },
            '56': {
                start: {
                    line: 122,
                    column: 16
                },
                end: {
                    line: 122,
                    column: 80
                }
            },
            '57': {
                start: {
                    line: 127,
                    column: 8
                },
                end: {
                    line: 133,
                    column: 11
                }
            },
            '58': {
                start: {
                    line: 128,
                    column: 12
                },
                end: {
                    line: 132,
                    column: 15
                }
            },
            '59': {
                start: {
                    line: 129,
                    column: 16
                },
                end: {
                    line: 131,
                    column: 18
                }
            },
            '60': {
                start: {
                    line: 137,
                    column: 8
                },
                end: {
                    line: 153,
                    column: 11
                }
            },
            '61': {
                start: {
                    line: 138,
                    column: 12
                },
                end: {
                    line: 141,
                    column: 15
                }
            },
            '62': {
                start: {
                    line: 139,
                    column: 16
                },
                end: {
                    line: 139,
                    column: 48
                }
            },
            '63': {
                start: {
                    line: 140,
                    column: 16
                },
                end: {
                    line: 140,
                    column: 30
                }
            },
            '64': {
                start: {
                    line: 142,
                    column: 12
                },
                end: {
                    line: 144,
                    column: 15
                }
            },
            '65': {
                start: {
                    line: 143,
                    column: 16
                },
                end: {
                    line: 143,
                    column: 90
                }
            },
            '66': {
                start: {
                    line: 145,
                    column: 12
                },
                end: {
                    line: 147,
                    column: 15
                }
            },
            '67': {
                start: {
                    line: 146,
                    column: 16
                },
                end: {
                    line: 146,
                    column: 94
                }
            },
            '68': {
                start: {
                    line: 148,
                    column: 12
                },
                end: {
                    line: 151,
                    column: 14
                }
            },
            '69': {
                start: {
                    line: 149,
                    column: 16
                },
                end: {
                    line: 149,
                    column: 54
                }
            },
            '70': {
                start: {
                    line: 150,
                    column: 16
                },
                end: {
                    line: 150,
                    column: 39
                }
            },
            '71': {
                start: {
                    line: 152,
                    column: 12
                },
                end: {
                    line: 152,
                    column: 68
                }
            },
            '72': {
                start: {
                    line: 158,
                    column: 8
                },
                end: {
                    line: 160,
                    column: 10
                }
            },
            '73': {
                start: {
                    line: 163,
                    column: 22
                },
                end: {
                    line: 163,
                    column: 27
                }
            },
            '74': {
                start: {
                    line: 164,
                    column: 19
                },
                end: {
                    line: 164,
                    column: 47
                }
            },
            '75': {
                start: {
                    line: 165,
                    column: 8
                },
                end: {
                    line: 169,
                    column: 9
                }
            },
            '76': {
                start: {
                    line: 166,
                    column: 12
                },
                end: {
                    line: 168,
                    column: 13
                }
            },
            '77': {
                start: {
                    line: 167,
                    column: 16
                },
                end: {
                    line: 167,
                    column: 31
                }
            },
            '78': {
                start: {
                    line: 170,
                    column: 8
                },
                end: {
                    line: 170,
                    column: 23
                }
            },
            '79': {
                start: {
                    line: 174,
                    column: 0
                },
                end: {
                    line: 179,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 182
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 18,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 66
                    },
                    end: {
                        line: 20,
                        column: 13
                    }
                },
                line: 18
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 21,
                        column: 23
                    },
                    end: {
                        line: 21,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 34
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                },
                line: 21
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 26
                    },
                    end: {
                        line: 23,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 37
                    },
                    end: {
                        line: 35,
                        column: 13
                    }
                },
                line: 23
            },
            '4': {
                name: 'buildToggler',
                decl: {
                    start: {
                        line: 41,
                        column: 17
                    },
                    end: {
                        line: 41,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 43
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                line: 41
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 42,
                        column: 19
                    },
                    end: {
                        line: 42,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 30
                    },
                    end: {
                        line: 44,
                        column: 13
                    }
                },
                line: 42
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 48,
                        column: 4
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 14
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 48
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 60,
                        column: 47
                    },
                    end: {
                        line: 60,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 61
                    },
                    end: {
                        line: 65,
                        column: 9
                    }
                },
                line: 60
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 68,
                        column: 4
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 20
                    },
                    end: {
                        line: 76,
                        column: 5
                    }
                },
                line: 68
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 78,
                        column: 4
                    },
                    end: {
                        line: 78,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 78,
                        column: 18
                    },
                    end: {
                        line: 81,
                        column: 5
                    }
                },
                line: 78
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 83,
                        column: 4
                    },
                    end: {
                        line: 83,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 18
                    },
                    end: {
                        line: 85,
                        column: 5
                    }
                },
                line: 83
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 87,
                        column: 4
                    },
                    end: {
                        line: 87,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 87,
                        column: 28
                    },
                    end: {
                        line: 95,
                        column: 5
                    }
                },
                line: 87
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 92,
                        column: 18
                    },
                    end: {
                        line: 92,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 24
                    },
                    end: {
                        line: 94,
                        column: 13
                    }
                },
                line: 92
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 97,
                        column: 4
                    },
                    end: {
                        line: 97,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 97,
                        column: 28
                    },
                    end: {
                        line: 103,
                        column: 5
                    }
                },
                line: 97
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 105,
                        column: 4
                    },
                    end: {
                        line: 105,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 105,
                        column: 28
                    },
                    end: {
                        line: 107,
                        column: 5
                    }
                },
                line: 105
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 109,
                        column: 4
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 19
                    },
                    end: {
                        line: 114,
                        column: 5
                    }
                },
                line: 109
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 116,
                        column: 4
                    },
                    end: {
                        line: 116,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 116,
                        column: 30
                    },
                    end: {
                        line: 124,
                        column: 5
                    }
                },
                line: 116
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 121,
                        column: 18
                    },
                    end: {
                        line: 121,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 121,
                        column: 24
                    },
                    end: {
                        line: 123,
                        column: 13
                    }
                },
                line: 121
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 126,
                        column: 4
                    },
                    end: {
                        line: 126,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 126,
                        column: 28
                    },
                    end: {
                        line: 134,
                        column: 5
                    }
                },
                line: 126
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 127,
                        column: 90
                    },
                    end: {
                        line: 127,
                        column: 91
                    }
                },
                loc: {
                    start: {
                        line: 127,
                        column: 110
                    },
                    end: {
                        line: 133,
                        column: 9
                    }
                },
                line: 127
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 128,
                        column: 35
                    },
                    end: {
                        line: 128,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 128,
                        column: 46
                    },
                    end: {
                        line: 132,
                        column: 13
                    }
                },
                line: 128
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 136,
                        column: 4
                    },
                    end: {
                        line: 136,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 136,
                        column: 18
                    },
                    end: {
                        line: 155,
                        column: 5
                    }
                },
                line: 136
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 137,
                        column: 35
                    },
                    end: {
                        line: 137,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 41
                    },
                    end: {
                        line: 153,
                        column: 9
                    }
                },
                line: 137
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 138,
                        column: 39
                    },
                    end: {
                        line: 138,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 50
                    },
                    end: {
                        line: 141,
                        column: 13
                    }
                },
                line: 138
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 142,
                        column: 38
                    },
                    end: {
                        line: 142,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 142,
                        column: 48
                    },
                    end: {
                        line: 144,
                        column: 13
                    }
                },
                line: 142
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 145,
                        column: 38
                    },
                    end: {
                        line: 145,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 55
                    },
                    end: {
                        line: 147,
                        column: 13
                    }
                },
                line: 145
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 148,
                        column: 106
                    },
                    end: {
                        line: 148,
                        column: 107
                    }
                },
                loc: {
                    start: {
                        line: 148,
                        column: 123
                    },
                    end: {
                        line: 151,
                        column: 13
                    }
                },
                line: 148
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 157,
                        column: 4
                    },
                    end: {
                        line: 157,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 157,
                        column: 19
                    },
                    end: {
                        line: 161,
                        column: 5
                    }
                },
                line: 157
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 162,
                        column: 4
                    },
                    end: {
                        line: 162,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 162,
                        column: 19
                    },
                    end: {
                        line: 171,
                        column: 5
                    }
                },
                line: 162
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 29,
                        column: 16
                    },
                    end: {
                        line: 34,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 29,
                        column: 16
                    },
                    end: {
                        line: 34,
                        column: 17
                    }
                }, {
                    start: {
                        line: 29,
                        column: 16
                    },
                    end: {
                        line: 34,
                        column: 17
                    }
                }],
                line: 29
            },
            '1': {
                loc: {
                    start: {
                        line: 61,
                        column: 12
                    },
                    end: {
                        line: 64,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 61,
                        column: 12
                    },
                    end: {
                        line: 64,
                        column: 13
                    }
                }, {
                    start: {
                        line: 61,
                        column: 12
                    },
                    end: {
                        line: 64,
                        column: 13
                    }
                }],
                line: 61
            },
            '2': {
                loc: {
                    start: {
                        line: 69,
                        column: 8
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 69,
                        column: 8
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                }, {
                    start: {
                        line: 69,
                        column: 8
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                }],
                line: 69
            },
            '3': {
                loc: {
                    start: {
                        line: 71,
                        column: 12
                    },
                    end: {
                        line: 73,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 71,
                        column: 12
                    },
                    end: {
                        line: 73,
                        column: 13
                    }
                }, {
                    start: {
                        line: 71,
                        column: 12
                    },
                    end: {
                        line: 73,
                        column: 13
                    }
                }],
                line: 71
            },
            '4': {
                loc: {
                    start: {
                        line: 71,
                        column: 16
                    },
                    end: {
                        line: 71,
                        column: 33
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 71,
                        column: 16
                    },
                    end: {
                        line: 71,
                        column: 17
                    }
                }, {
                    start: {
                        line: 71,
                        column: 21
                    },
                    end: {
                        line: 71,
                        column: 33
                    }
                }],
                line: 71
            },
            '5': {
                loc: {
                    start: {
                        line: 99,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 99,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }, {
                    start: {
                        line: 99,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }],
                line: 99
            },
            '6': {
                loc: {
                    start: {
                        line: 158,
                        column: 16
                    },
                    end: {
                        line: 159,
                        column: 46
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 158,
                        column: 16
                    },
                    end: {
                        line: 158,
                        column: 47
                    }
                }, {
                    start: {
                        line: 158,
                        column: 51
                    },
                    end: {
                        line: 158,
                        column: 75
                    }
                }, {
                    start: {
                        line: 159,
                        column: 12
                    },
                    end: {
                        line: 159,
                        column: 46
                    }
                }],
                line: 158
            },
            '7': {
                loc: {
                    start: {
                        line: 164,
                        column: 19
                    },
                    end: {
                        line: 164,
                        column: 47
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 164,
                        column: 19
                    },
                    end: {
                        line: 164,
                        column: 32
                    }
                }, {
                    start: {
                        line: 164,
                        column: 36
                    },
                    end: {
                        line: 164,
                        column: 47
                    }
                }],
                line: 164
            },
            '8': {
                loc: {
                    start: {
                        line: 165,
                        column: 8
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 165,
                        column: 8
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                }, {
                    start: {
                        line: 165,
                        column: 8
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                }],
                line: 165
            },
            '9': {
                loc: {
                    start: {
                        line: 166,
                        column: 12
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 166,
                        column: 12
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                }, {
                    start: {
                        line: 166,
                        column: 12
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                }],
                line: 166
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NewMeetingController = function () {
    function NewMeetingController(UsersTable, TemplatesTable, TemplateTopicsTable, MeetingsTable, MeetingUsersTable, TopicsTable, MeetingFilesTable, $mdDialog, $mdSidenav, $rootScope, $scope, $state) {
        var _this = this;

        _classCallCheck(this, NewMeetingController);

        cov_24qpqabz0h.f[0]++;
        cov_24qpqabz0h.s[0]++;

        this.users = UsersTable.$search();
        cov_24qpqabz0h.s[1]++;
        this.templates = TemplatesTable.$search();

        cov_24qpqabz0h.s[2]++;
        this.templateTopicsTable = TemplateTopicsTable;
        cov_24qpqabz0h.s[3]++;
        this.meetingsTable = MeetingsTable;
        cov_24qpqabz0h.s[4]++;
        this.meetingUsersTable = MeetingUsersTable;
        cov_24qpqabz0h.s[5]++;
        this.topicsTable = TopicsTable;
        cov_24qpqabz0h.s[6]++;
        this.meetingFilesTable = MeetingFilesTable;

        cov_24qpqabz0h.s[7]++;
        this.$mdDialog = $mdDialog;
        cov_24qpqabz0h.s[8]++;
        this.$rootScope = $rootScope;
        cov_24qpqabz0h.s[9]++;
        this.$scope = $scope;
        cov_24qpqabz0h.s[10]++;
        this.$state = $state;
        cov_24qpqabz0h.s[11]++;
        this.sortableListeners = {
            accept: function accept(sourceItemHandleScope, destSortableScope) {
                cov_24qpqabz0h.f[1]++;
                cov_24qpqabz0h.s[12]++;

                return true;
            },
            itemMoved: function itemMoved(event) {
                cov_24qpqabz0h.f[2]++;
            },
            orderChanged: function orderChanged(event) {
                cov_24qpqabz0h.f[3]++;

                var newPos = (cov_24qpqabz0h.s[13]++, event.dest.index);
                var topic = (cov_24qpqabz0h.s[14]++, event.source.itemScope.topic);
                var index = (cov_24qpqabz0h.s[15]++, _this.meetingTopics.indexOf(topic));
                var oldTopic = (cov_24qpqabz0h.s[16]++, _this.meetingTopics[index]);
                cov_24qpqabz0h.s[17]++;
                _this.meetingTopics.splice(index, 1);
                cov_24qpqabz0h.s[18]++;
                if (newPos >= _this.meetingTopics.length) {
                    cov_24qpqabz0h.b[0][0]++;
                    cov_24qpqabz0h.s[19]++;

                    _this.meetingTopics.push(oldTopic);
                } else {
                    cov_24qpqabz0h.b[0][1]++;
                    cov_24qpqabz0h.s[20]++;

                    _this.meetingTopics.splice(newPos, 0, oldTopic);
                }
            },
            placeholder: '<div style="min-height: 60px;"></div>'
        };

        cov_24qpqabz0h.s[21]++;
        this.toggleRight = buildToggler('right');

        function buildToggler(componentId) {
            cov_24qpqabz0h.f[4]++;
            cov_24qpqabz0h.s[22]++;

            return function () {
                cov_24qpqabz0h.f[5]++;
                cov_24qpqabz0h.s[23]++;

                $mdSidenav(componentId).toggle();
            };
        }
    }

    _createClass(NewMeetingController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            cov_24qpqabz0h.f[6]++;
            cov_24qpqabz0h.s[24]++;

            this.date = new Date();
            cov_24qpqabz0h.s[25]++;
            this.meetingDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 1, 10, 30, 0);
            cov_24qpqabz0h.s[26]++;
            this.meeting = this.meetingsTable.$build({ user: this.$rootScope.me.id, name: 'Weekly Meeting', scheduleDate: this.meetingDate });
            cov_24qpqabz0h.s[27]++;
            this.userNotes = '';
            cov_24qpqabz0h.s[28]++;
            this.meetingUsers = [];
            cov_24qpqabz0h.s[29]++;
            this.meetingFiles = [];
            cov_24qpqabz0h.s[30]++;
            this.meetingTopics = [];

            cov_24qpqabz0h.s[31]++;
            this.newDescription = '';
            cov_24qpqabz0h.s[32]++;
            this.duration = '01:00';
            cov_24qpqabz0h.s[33]++;
            this.meeting.scheduleDuration = this.duration + ":00";
            cov_24qpqabz0h.s[34]++;
            this.$scope.$watch('$ctrl.fileUpload', function (uploaded) {
                cov_24qpqabz0h.f[7]++;
                cov_24qpqabz0h.s[35]++;

                if (angular.isString(uploaded)) {
                    cov_24qpqabz0h.b[1][0]++;
                    cov_24qpqabz0h.s[36]++;

                    _this2.meetingFiles.push(_this2.fileUpload);
                    cov_24qpqabz0h.s[37]++;
                    _this2.fileUpload = null;
                } else {
                    cov_24qpqabz0h.b[1][1]++;
                }
            });
        }
    }, {
        key: 'fileToName',
        value: function fileToName(url) {
            cov_24qpqabz0h.f[8]++;
            cov_24qpqabz0h.s[38]++;

            if (url) {
                cov_24qpqabz0h.b[2][0]++;

                var m = (cov_24qpqabz0h.s[39]++, url.toString().match(/.*\/(.+?)\.(.+)/));
                cov_24qpqabz0h.s[40]++;
                if ((cov_24qpqabz0h.b[4][0]++, m) && (cov_24qpqabz0h.b[4][1]++, m.length > 1)) {
                    cov_24qpqabz0h.b[3][0]++;
                    cov_24qpqabz0h.s[41]++;

                    return m[1] + '.' + m[2];
                } else {
                    cov_24qpqabz0h.b[3][1]++;
                }
            } else {
                cov_24qpqabz0h.b[2][1]++;
            }
            cov_24qpqabz0h.s[42]++;
            return "";
        }
    }, {
        key: 'editDuration',
        value: function editDuration() {
            cov_24qpqabz0h.f[9]++;

            var scheduledDuration = (cov_24qpqabz0h.s[43]++, this.duration + ':00');
            cov_24qpqabz0h.s[44]++;
            this.meeting.scheduleDuration = scheduledDuration;
        }
    }, {
        key: 'addUser',
        value: function addUser(user) {
            cov_24qpqabz0h.f[10]++;
            cov_24qpqabz0h.s[45]++;

            this.meetingUsers.push(user);
        }
    }, {
        key: 'confirmRemoveUser',
        value: function confirmRemoveUser(user) {
            var _this3 = this;

            cov_24qpqabz0h.f[11]++;
            cov_24qpqabz0h.s[46]++;

            this.$mdDialog.show(this.$mdDialog.confirm().title("Are you sure you want to remove user from meeting?").ok('Remove').cancel('Cancel')).then(function () {
                cov_24qpqabz0h.f[12]++;
                cov_24qpqabz0h.s[47]++;

                _this3.meetingUsers.splice(_this3.meetingUsers.indexOf(user), 1);
            });
        }
    }, {
        key: 'userIsParticipant',
        value: function userIsParticipant(user) {
            cov_24qpqabz0h.f[13]++;

            var isParticipant = (cov_24qpqabz0h.s[48]++, false);
            cov_24qpqabz0h.s[49]++;
            if (this.meetingUsers.indexOf(user) !== -1) {
                cov_24qpqabz0h.b[5][0]++;
                cov_24qpqabz0h.s[50]++;

                isParticipant = true;
            } else {
                cov_24qpqabz0h.b[5][1]++;
            }
            cov_24qpqabz0h.s[51]++;
            return isParticipant;
        }
    }, {
        key: 'removeMeetingFile',
        value: function removeMeetingFile(file) {
            cov_24qpqabz0h.f[14]++;
            cov_24qpqabz0h.s[52]++;

            this.meetingFiles.splice(this.meetingFiles.indexOf(file), 1);
        }
    }, {
        key: 'addTopic',
        value: function addTopic(name) {
            cov_24qpqabz0h.f[15]++;
            cov_24qpqabz0h.s[53]++;

            this.meetingTopics.push(this.topicsTable.$build({ description: name }));
            cov_24qpqabz0h.s[54]++;
            this.newDescription = '';
        }
    }, {
        key: 'confirmDeleteTopic',
        value: function confirmDeleteTopic(topic) {
            var _this4 = this;

            cov_24qpqabz0h.f[16]++;
            cov_24qpqabz0h.s[55]++;

            this.$mdDialog.show(this.$mdDialog.confirm().title("Are you sure you want to remove this topic?").ok('Remove').cancel('Cancel')).then(function () {
                cov_24qpqabz0h.f[17]++;
                cov_24qpqabz0h.s[56]++;

                _this4.meetingTopics.splice(_this4.meetingTopics.indexOf(topic), 1);
            });
        }
    }, {
        key: 'addTemplate',
        value: function addTemplate(templateId) {
            var _this5 = this;

            cov_24qpqabz0h.f[18]++;
            cov_24qpqabz0h.s[57]++;

            this.templateTopicsTable.$search({ template: templateId, ordering: 'order' }).$then(function (templateTopics) {
                cov_24qpqabz0h.f[19]++;
                cov_24qpqabz0h.s[58]++;

                templateTopics.forEach(function (topic) {
                    cov_24qpqabz0h.f[20]++;
                    cov_24qpqabz0h.s[59]++;

                    _this5.meetingTopics.push(_this5.topicsTable.$build({ description: topic.name }));
                });
            });
        }
    }, {
        key: 'saveMeeting',
        value: function saveMeeting() {
            var _this6 = this;

            cov_24qpqabz0h.f[21]++;
            cov_24qpqabz0h.s[60]++;

            this.meeting.$save().$then(function () {
                cov_24qpqabz0h.f[22]++;
                cov_24qpqabz0h.s[61]++;

                _this6.meetingTopics.forEach(function (topic) {
                    cov_24qpqabz0h.f[23]++;
                    cov_24qpqabz0h.s[62]++;

                    topic.meeting = _this6.meeting.id;
                    cov_24qpqabz0h.s[63]++;
                    topic.$save();
                });
                cov_24qpqabz0h.s[64]++;
                _this6.meetingUsers.forEach(function (user) {
                    cov_24qpqabz0h.f[24]++;
                    cov_24qpqabz0h.s[65]++;

                    _this6.meetingUsersTable.$create({ user: user.id, meeting: _this6.meeting.id });
                });
                cov_24qpqabz0h.s[66]++;
                _this6.meetingFiles.forEach(function (meetingFile) {
                    cov_24qpqabz0h.f[25]++;
                    cov_24qpqabz0h.s[67]++;

                    _this6.meetingFilesTable.$create({ meeting: _this6.meeting.id, file: meetingFile });
                });
                cov_24qpqabz0h.s[68]++;
                _this6.meetingUsersTable.$search({ user: _this6.$rootScope.me.id, meeting: _this6.meeting.id }).$then(function (meetingUser) {
                    cov_24qpqabz0h.f[26]++;
                    cov_24qpqabz0h.s[69]++;

                    meetingUser[0].notes = _this6.userNotes;
                    cov_24qpqabz0h.s[70]++;
                    meetingUser[0].$save();
                });
                cov_24qpqabz0h.s[71]++;
                _this6.$state.go('meetingDetails', { id: _this6.meeting.id });
            });
        }
    }, {
        key: 'requirements',
        value: function requirements() {
            cov_24qpqabz0h.f[27]++;
            cov_24qpqabz0h.s[72]++;

            return (cov_24qpqabz0h.b[6][0]++, this.meeting.name === undefined) || (cov_24qpqabz0h.b[6][1]++, this.meeting.name === '') || (cov_24qpqabz0h.b[6][2]++, this.meeting.scheduleDate === null);
        }
    }, {
        key: 'isEnter',
        value: function isEnter(event) {
            cov_24qpqabz0h.f[28]++;

            var isEnter = (cov_24qpqabz0h.s[73]++, false);
            var code = (cov_24qpqabz0h.s[74]++, (cov_24qpqabz0h.b[7][0]++, event.keyCode) || (cov_24qpqabz0h.b[7][1]++, event.which));
            cov_24qpqabz0h.s[75]++;
            if (code === 13) {
                cov_24qpqabz0h.b[8][0]++;
                cov_24qpqabz0h.s[76]++;

                if (!event.shiftKey) {
                    cov_24qpqabz0h.b[9][0]++;
                    cov_24qpqabz0h.s[77]++;

                    isEnter = true;
                } else {
                    cov_24qpqabz0h.b[9][1]++;
                }
            } else {
                cov_24qpqabz0h.b[8][1]++;
            }
            cov_24qpqabz0h.s[78]++;
            return isEnter;
        }
    }]);

    return NewMeetingController;
}();

cov_24qpqabz0h.s[79]++;


angular.module('app').component('newMeeting', {
    templateUrl: 'app/components/newMeeting/newMeeting.html',
    controller: NewMeetingController
});