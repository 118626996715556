'use strict';

var cov_bk216egus = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/createTemplateModal/createTemplateModal.js',
        hash = '04b15589df2340deca2c533d2e8a2ee5577277cf',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/createTemplateModal/createTemplateModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 35,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 69
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 22
                },
                end: {
                    line: 10,
                    column: 26
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 53
                }
            },
            '6': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 10
                }
            },
            '9': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 14
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 41
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 35,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewTemplateModalService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 49
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 23
                    },
                    end: {
                        line: 11,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 35
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: 'CreateTemplateModalController',
                decl: {
                    start: {
                        line: 22,
                        column: 13
                    },
                    end: {
                        line: 22,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 79
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 29
                    },
                    end: {
                        line: 25,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 41
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                line: 25
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 29,
                        column: 32
                    },
                    end: {
                        line: 29,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 44
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                line: 29
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 30,
                        column: 45
                    },
                    end: {
                        line: 30,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 65
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                line: 30
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_bk216egus.s[0]++;
(function () {
    'use strict';

    cov_bk216egus.f[0]++;
    cov_bk216egus.s[1]++;
    angular.module('app').service('NewTemplateModalService', NewTemplateModalService);

    /* @ngInject */
    function NewTemplateModalService($mdDialog) {
        cov_bk216egus.f[1]++;

        var Service = (cov_bk216egus.s[2]++, this);
        cov_bk216egus.s[3]++;
        this.display = function () {
            cov_bk216egus.f[2]++;
            cov_bk216egus.s[4]++;

            return $mdDialog.show({
                templateUrl: 'app/components/createTemplateModal/createTemplateModal.html',
                controller: CreateTemplateModalController,
                clickOutsideToClose: true,
                escapeToClose: true
            });
        };
    }

    /* @ngInject */
    function CreateTemplateModalController($scope, $mdDialog, TemplatesTable) {
        cov_bk216egus.f[3]++;
        cov_bk216egus.s[5]++;

        $scope.newTemplate = TemplatesTable.$build();

        cov_bk216egus.s[6]++;
        $scope.closeDialog = function () {
            cov_bk216egus.f[4]++;
            cov_bk216egus.s[7]++;

            $mdDialog.cancel();
        };

        cov_bk216egus.s[8]++;
        $scope.createTemplate = function () {
            cov_bk216egus.f[5]++;
            cov_bk216egus.s[9]++;

            $scope.newTemplate.$save().$then(function (template) {
                cov_bk216egus.f[6]++;
                cov_bk216egus.s[10]++;

                $mdDialog.hide(template);
            });
        };
    }
})(angular);