'use strict';

var cov_1zobpbmjy2 = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/homeView/homeView.js',
        hash = 'aec94b3ccf46166690c85d6c68cb5cc3c272daad',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/homeView/homeView.js',
        statementMap: {
            '0': {
                start: {
                    line: 10,
                    column: 0
                },
                end: {
                    line: 18,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 19
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 5,
                        column: 4
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 5,
                        column: 15
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 5
            }
        },
        branchMap: {},
        s: {
            '0': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HomeViewController = function () {
    function HomeViewController() {
        _classCallCheck(this, HomeViewController);

        cov_1zobpbmjy2.f[0]++;
    }

    _createClass(HomeViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1zobpbmjy2.f[1]++;
        }
    }]);

    return HomeViewController;
}();

cov_1zobpbmjy2.s[0]++;


angular.module('app').component('homeView', {
    templateUrl: 'app/components/homeView/homeView.html',
    controller: HomeViewController,
    bindings: {
        welcomeMsg: '<'
    }
});