'use strict';

var cov_y7czjpqlz = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/shared/js/media/media.filter.js',
        hash = '8e919dc84462fce5b2dd6ccf543caa25dba88c95',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/shared/js/media/media.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 18,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 38
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 15,
                    column: 13
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 12,
                    column: 58
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 14,
                    column: 28
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 18,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'MediaUrl',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 35
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 32
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                }, {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                }],
                line: 11
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_y7czjpqlz.s[0]++;
(function () {
    'use strict';

    cov_y7czjpqlz.f[0]++;
    cov_y7czjpqlz.s[1]++;
    angular.module('app').filter('mediaUrl', MediaUrl);

    /* @ngInject */
    function MediaUrl(AppSettings) {
        cov_y7czjpqlz.f[1]++;
        cov_y7czjpqlz.s[2]++;

        return function (input) {
            cov_y7czjpqlz.f[2]++;
            cov_y7czjpqlz.s[3]++;

            if (input) {
                cov_y7czjpqlz.b[0][0]++;
                cov_y7czjpqlz.s[4]++;

                return AppSettings.mediaUrl + '/' + input;
            } else {
                cov_y7czjpqlz.b[0][1]++;
                cov_y7czjpqlz.s[5]++;

                return null;
            }
        };
    }
})();