'use strict';

var cov_lelujn63z = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/auth/auth.activate.controller.js',
        hash = '74028529a67fd761d9d1933779740705aa1f60e4',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/auth/auth.activate.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 40,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 66
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 17
                },
                end: {
                    line: 10,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 19
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 31
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 15
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 26,
                    column: 18
                }
            },
            '8': {
                start: {
                    line: 27,
                    column: 16
                },
                end: {
                    line: 29,
                    column: 19
                }
            },
            '9': {
                start: {
                    line: 28,
                    column: 20
                },
                end: {
                    line: 28,
                    column: 65
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 18
                }
            },
            '11': {
                start: {
                    line: 36,
                    column: 16
                },
                end: {
                    line: 36,
                    column: 35
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 40,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'AuthActivateController',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 100
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 14,
                        column: 17
                    },
                    end: {
                        line: 14,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 28
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                },
                line: 14
            },
            '3': {
                name: 'successCallback',
                decl: {
                    start: {
                        line: 21,
                        column: 29
                    },
                    end: {
                        line: 21,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 47
                    },
                    end: {
                        line: 30,
                        column: 13
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 27,
                        column: 47
                    },
                    end: {
                        line: 27,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 59
                    },
                    end: {
                        line: 29,
                        column: 17
                    }
                },
                line: 27
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 15
                    },
                    end: {
                        line: 30,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 27
                    },
                    end: {
                        line: 37,
                        column: 13
                    }
                },
                line: 30
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 15
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_lelujn63z.s[0]++;
(function () {
    'use strict';

    cov_lelujn63z.f[0]++;
    cov_lelujn63z.s[1]++;
    angular.module('app').controller('AuthActivateController', AuthActivateController);

    /* @ngInject */
    function AuthActivateController($http, $state, $stateParams, AppSettings, $mdToast, $rootScope) {
        cov_lelujn63z.f[1]++;

        var vm = (cov_lelujn63z.s[2]++, this);

        cov_lelujn63z.s[3]++;
        activate();

        function activate() {
            cov_lelujn63z.f[2]++;
            cov_lelujn63z.s[4]++;

            if (!$stateParams.token) {
                cov_lelujn63z.b[0][0]++;
                cov_lelujn63z.s[5]++;

                $state.go('/');
            } else {
                cov_lelujn63z.b[0][1]++;
            }
            cov_lelujn63z.s[6]++;
            vm.requestStatus = $http({
                method: 'GET',
                url: AppSettings.apiUrl + '/users/activate/?token=' + $stateParams.token
            }).then(function successCallback() {
                cov_lelujn63z.f[3]++;
                cov_lelujn63z.s[7]++;

                $mdToast.show($mdToast.simple().textContent('Your account has been activated!').hideDelay(3000));
                cov_lelujn63z.s[8]++;
                $state.go('projectsList').then(function () {
                    cov_lelujn63z.f[4]++;
                    cov_lelujn63z.s[9]++;

                    $rootScope.openHeaderPage('loginPageActive');
                });
            }, function () {
                cov_lelujn63z.f[5]++;
                cov_lelujn63z.s[10]++;

                $mdToast.show($mdToast.simple().textContent('Activation error!').hideDelay(3000));
                cov_lelujn63z.s[11]++;
                $state.go('login');
            });
        }
    }
})();