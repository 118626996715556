'use strict';

var cov_x60onx50x = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/createMeetingModal/createMeetingModal.js',
        hash = 'a6e18428b93da5e0a9cdd5a7fde9e43981a5a434',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/createMeetingModal/createMeetingModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 38,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 67
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 22
                },
                end: {
                    line: 10,
                    column: 26
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 61
                }
            },
            '6': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 61
                }
            },
            '7': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 34
                }
            },
            '8': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 10
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 31
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 10
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 35
                },
                end: {
                    line: 31,
                    column: 58
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 66
                }
            },
            '13': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 40
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 38,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewMeetingModalService',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 48
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 23
                    },
                    end: {
                        line: 11,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 35
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 11
            },
            '3': {
                name: 'CreateMeetingModalController',
                decl: {
                    start: {
                        line: 22,
                        column: 13
                    },
                    end: {
                        line: 22,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 101
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 29
                    },
                    end: {
                        line: 26,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 41
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 31
                    },
                    end: {
                        line: 30,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 43
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 33,
                        column: 44
                    },
                    end: {
                        line: 33,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 63
                    },
                    end: {
                        line: 35,
                        column: 13
                    }
                },
                line: 33
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_x60onx50x.s[0]++;
(function () {
    'use strict';

    cov_x60onx50x.f[0]++;
    cov_x60onx50x.s[1]++;
    angular.module('app').service('NewMeetingModalService', NewMeetingModalService);

    /* @ngInject */
    function NewMeetingModalService($mdDialog) {
        cov_x60onx50x.f[1]++;

        var Service = (cov_x60onx50x.s[2]++, this);
        cov_x60onx50x.s[3]++;
        this.display = function () {
            cov_x60onx50x.f[2]++;
            cov_x60onx50x.s[4]++;

            return $mdDialog.show({
                templateUrl: 'app/components/createMeetingModal/createMeetingModal.html',
                controller: CreateMeetingModalController,
                clickOutsideToClose: true,
                escapeToClose: true
            });
        };
    }

    /* @ngInject */
    function CreateMeetingModalController($scope, $mdDialog, MeetingsTable, NewMeetingModalService) {
        cov_x60onx50x.f[3]++;
        cov_x60onx50x.s[5]++;

        this.NewMeetingModalService = NewMeetingModalService;
        cov_x60onx50x.s[6]++;
        $scope.newMeeting = MeetingsTable.$build({ name: '' });
        cov_x60onx50x.s[7]++;
        $scope.duration = '00:00';
        cov_x60onx50x.s[8]++;
        $scope.closeDialog = function () {
            cov_x60onx50x.f[4]++;
            cov_x60onx50x.s[9]++;

            $mdDialog.cancel();
        };

        cov_x60onx50x.s[10]++;
        $scope.createMeeting = function () {
            cov_x60onx50x.f[5]++;

            var scheduleDuration = (cov_x60onx50x.s[11]++, $scope.duration + ':00');
            cov_x60onx50x.s[12]++;
            $scope.newMeeting.scheduleDuration = scheduleDuration;
            cov_x60onx50x.s[13]++;
            $scope.newMeeting.$save().$then(function (meeting) {
                cov_x60onx50x.f[6]++;
                cov_x60onx50x.s[14]++;

                $mdDialog.hide(meeting);
            });
        };
    }
})(angular);