'use strict';

var cov_25290g87wp = function () {
    var path = '/home/lobo/repos/meetings-app/src/app/components/meetingsList/meetingsList.js',
        hash = '3e22ec171eafaa952019752ea686ce54dc984e3e',
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/meetings-app/src/app/components/meetingsList/meetingsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 61
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 35
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 26
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 55
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 58
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 24
                }
            },
            '9': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 45
                }
            },
            '10': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 40
                }
            },
            '14': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 49
                }
            },
            '15': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 32
                }
            },
            '17': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 41
                }
            },
            '18': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 45
                }
            },
            '19': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 24
                }
            },
            '20': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 11
                }
            },
            '21': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 40
                }
            },
            '22': {
                start: {
                    line: 53,
                    column: 0
                },
                end: {
                    line: 80,
                    column: 7
                }
            },
            '23': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 79,
                    column: 10
                }
            },
            '24': {
                start: {
                    line: 67,
                    column: 16
                },
                end: {
                    line: 77,
                    column: 19
                }
            },
            '25': {
                start: {
                    line: 68,
                    column: 39
                },
                end: {
                    line: 68,
                    column: 46
                }
            },
            '26': {
                start: {
                    line: 69,
                    column: 20
                },
                end: {
                    line: 71,
                    column: 21
                }
            },
            '27': {
                start: {
                    line: 70,
                    column: 24
                },
                end: {
                    line: 70,
                    column: 61
                }
            },
            '28': {
                start: {
                    line: 72,
                    column: 20
                },
                end: {
                    line: 72,
                    column: 62
                }
            },
            '29': {
                start: {
                    line: 74,
                    column: 20
                },
                end: {
                    line: 76,
                    column: 21
                }
            },
            '30': {
                start: {
                    line: 75,
                    column: 24
                },
                end: {
                    line: 75,
                    column: 40
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 66
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 15
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 22
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 26,
                        column: 4
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 33
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                line: 26
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 30
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 44
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 45,
                        column: 51
                    },
                    end: {
                        line: 45,
                        column: 52
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 64
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                },
                line: 45
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 47,
                        column: 11
                    },
                    end: {
                        line: 47,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 23
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 47
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 62,
                        column: 35
                    },
                    end: {
                        line: 62,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 47
                    },
                    end: {
                        line: 80,
                        column: 5
                    }
                },
                line: 62
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 66,
                        column: 18
                    },
                    end: {
                        line: 66,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 56
                    },
                    end: {
                        line: 78,
                        column: 13
                    }
                },
                line: 66
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 67,
                        column: 29
                    },
                    end: {
                        line: 67,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 41
                    },
                    end: {
                        line: 73,
                        column: 17
                    }
                },
                line: 67
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 73,
                        column: 19
                    },
                    end: {
                        line: 73,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 73,
                        column: 37
                    },
                    end: {
                        line: 77,
                        column: 17
                    }
                },
                line: 73
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 15,
                        column: 30
                    },
                    end: {
                        line: 15,
                        column: 57
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 15,
                        column: 30
                    },
                    end: {
                        line: 15,
                        column: 52
                    }
                }, {
                    start: {
                        line: 15,
                        column: 56
                    },
                    end: {
                        line: 15,
                        column: 57
                    }
                }],
                line: 15
            },
            '1': {
                loc: {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }, {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }],
                line: 27
            },
            '2': {
                loc: {
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                }, {
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                }],
                line: 31
            },
            '3': {
                loc: {
                    start: {
                        line: 74,
                        column: 20
                    },
                    end: {
                        line: 76,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 74,
                        column: 20
                    },
                    end: {
                        line: 76,
                        column: 21
                    }
                }, {
                    start: {
                        line: 74,
                        column: 20
                    },
                    end: {
                        line: 76,
                        column: 21
                    }
                }],
                line: 74
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MeetingsListController = function () {
    function MeetingsListController(NewMeetingModalService, $stateParams, $location) {
        _classCallCheck(this, MeetingsListController);

        cov_25290g87wp.f[0]++;
        cov_25290g87wp.s[0]++;

        this.NewMeetingModalService = NewMeetingModalService;
        cov_25290g87wp.s[1]++;
        this.$stateParams = $stateParams;
        cov_25290g87wp.s[2]++;
        this.$location = $location;
        cov_25290g87wp.s[3]++;
        this.filters = {};
    }

    _createClass(MeetingsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_25290g87wp.f[1]++;
            cov_25290g87wp.s[4]++;

            this.filters = this.$stateParams;

            cov_25290g87wp.s[5]++;
            this.projectFilter = this.$stateParams.project;

            cov_25290g87wp.s[6]++;
            this.meetings.$page = (cov_25290g87wp.b[0][0]++, this.$stateParams.page) || (cov_25290g87wp.b[0][1]++, 1);
            cov_25290g87wp.s[7]++;
            this.meetings.$params = {
                page_size: 25
            };
            cov_25290g87wp.s[8]++;
            this._refresh();
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            cov_25290g87wp.f[2]++;
            cov_25290g87wp.s[9]++;

            this.meetings.$refresh(this.filters);
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_25290g87wp.f[3]++;
            cov_25290g87wp.s[10]++;

            if (angular.isArray(value)) {
                cov_25290g87wp.b[1][0]++;
                cov_25290g87wp.s[11]++;

                value = value.join(',');
            } else {
                cov_25290g87wp.b[1][1]++;
            }

            cov_25290g87wp.s[12]++;
            if (value === null) {
                cov_25290g87wp.b[2][0]++;
                cov_25290g87wp.s[13]++;

                delete this.filters[filter];
                cov_25290g87wp.s[14]++;
                delete this.meetings.$params[filter];
            } else {
                cov_25290g87wp.b[2][1]++;
                cov_25290g87wp.s[15]++;

                this.filters[filter] = value;
            }

            cov_25290g87wp.s[16]++;
            this.meetings.$page = 1;
            cov_25290g87wp.s[17]++;
            this.$location.search('page', 1);
            cov_25290g87wp.s[18]++;
            this.$location.search(filter, value);
            cov_25290g87wp.s[19]++;
            this._refresh();
        }
    }, {
        key: 'displayNewMeetingModal',
        value: function displayNewMeetingModal() {
            var _this = this;

            cov_25290g87wp.f[4]++;
            cov_25290g87wp.s[20]++;

            this.NewMeetingModalService.display().then(function (meeting) {
                cov_25290g87wp.f[5]++;
                cov_25290g87wp.s[21]++;

                _this.meetings.$add(meeting);
            }, function () {
                cov_25290g87wp.f[6]++;
            });
        }
    }]);

    return MeetingsListController;
}();

cov_25290g87wp.s[22]++;


angular.module('app').component('meetingsList', {
    templateUrl: 'app/components/meetingsList/meetingsList.html',
    controller: MeetingsListController,
    bindings: {
        meetings: '<'
    }
}).directive('forceSelectFocus', function () {
    cov_25290g87wp.f[7]++;
    cov_25290g87wp.s[23]++;

    return {
        restrict: 'A',
        require: ['^^mdSelect', '^ngModel'],
        link: function link(scope, element, controller) {
            cov_25290g87wp.f[8]++;
            cov_25290g87wp.s[24]++;

            scope.$watch(function () {
                cov_25290g87wp.f[9]++;

                var foundElement = (cov_25290g87wp.s[25]++, element);
                cov_25290g87wp.s[26]++;
                while (!foundElement.hasClass('md-select-menu-container')) {
                    cov_25290g87wp.s[27]++;

                    foundElement = foundElement.parent();
                }
                cov_25290g87wp.s[28]++;
                return foundElement.hasClass('md-active');
            }, function (newVal) {
                cov_25290g87wp.f[10]++;
                cov_25290g87wp.s[29]++;

                if (newVal) {
                    cov_25290g87wp.b[3][0]++;
                    cov_25290g87wp.s[30]++;

                    element.focus();
                } else {
                    cov_25290g87wp.b[3][1]++;
                }
            });
        }
    };
});